<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina
                Titlu="Bonuri consum"
                @on_add_clicked="show_dialog()"
                :AdaugaVisible="true"
              />
            </div>
          </template>
          <div class="filtre">
            <el-form
              @submit.prevent.native="refresh_info()"
              label-position="top"
            >
              <el-row :gutter="20">
                <el-col :md="4">
                  <el-form-item label="Data">
                    <el-date-picker
                      v-model="Filters.DataBonConsum"
                      type="date"
                      value-format="yyyy-MM-dd"
                      format="dd.MM.yyyy"
                    />
                  </el-form-item>
                </el-col>
                <el-col :md="4">
                  <el-form-item label="Nr. Doc">
                    <el-input v-model="Filters.Numar" />
                  </el-form-item>
                </el-col>
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button
                      type="primary"
                      native-type="submit"
                      @click="refresh_info()"
                    >
                      Aplica
                    </el-button>
                    <el-button
                      type="danger"
                      native-type="submit"
                      @click="reset()"
                    >
                      Reseteaza
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table :header-cell-style="{ background: '#393E46' }" :data="Results" class="tabelRandMic">
      <el-table-column prop="Id" label="#"></el-table-column>
      <el-table-column prop="Numar" label="Numar"></el-table-column>
      <el-table-column label="Data">
        <template slot-scope="scope">
          {{ scope.row.DataBonConsum | Data() }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="ValoareCuTVA"
        label="Valoare cu tva"
      ></el-table-column> -->
      <el-table-column
        prop="ValoareFaraTVA"
        label="Valoare fara tva"
      ></el-table-column>
      <el-table-column
        style="text-align: center;"
        fixed="right"
        label="Actiuni"
        width="150px"
      >
        <template slot-scope="scope">
          <el-tooltip content="Imprimare">
            <el-button type="info" size="mini" icon="el-icon-printer" circle @click="print(scope.row.Id)" class="butonReg" />
          </el-tooltip>
          <el-tooltip content="Descarca PDF">
            <el-button type="success" size="mini" icon="el-icon-download" circle @click="downloadPdf(scope.row.Id,scope.row.Numar)"  class="butonReg" />
          </el-tooltip>
          <el-tooltip content="Modificare">
            <!-- <el-tooltip v-if="$has_right('modificare-factura')" content="Modificare"> -->
            <el-button
              :disabled="scope.row.LunaBlocata"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              circle
              @click="show_dialog(scope.row.Id)"
            class="butonReg" />
          </el-tooltip>
    
          <el-tooltip content="Sterge" >
              <el-button  :disabled="scope.row.LunaBlocata" type="danger" size="mini" icon="el-icon-delete" circle @click="delete_item(scope.row)" class="butonReg"/>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager"
    />
    <Bonuri-consum-dialog ref="dlg" @save="refresh_info()" />
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Bonuri_consum_dialog from "@/pages/bonuri_consum/Bonuri_consum_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";


export default {
  name: "bonuri_consum",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Bonuri-consum-dialog": Bonuri_consum_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      Results: [],
      base_url: "",
      Info: {
        masini: [],
      },
      Filters: {
        DataBonConsum: "",
        NumeClient: "",
        CifClient: "",
        RegComClient: "",
        Numar: "",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      // if (!this.$has_right('vizualizare-facturi')){
      //       this.$router.push('/no-rights')
      //       return
      // }
      var response = await this.post("bonuri_consum/get_info");
      this.Info.masini = response.masini;
      //if( this.Filters.IdMasina.length > 0 ) this.Filters.IdMasina = this.Info.masini[0].Id;
      this.refresh_info();
    },

    async downloadPdf(id,numar){
      let res = await this.post('bonuri_consum/print_bonuri_consum', {IdFactura: id});
      //const toDownloadDocument = new jsPDF()
      const blankWindow = window.open('', '_blank');

      html2pdf(res.html, {
				margin: 0,
  			filename: 'Bonuri-consum_'+numar,
        html2canvas: { scale: 4 },
			}).then(() => {
        blankWindow.close();
      });
      // toDownloadDocument.html(res.html, {
      //   callback: () => {
      //     toDownloadDocument.save('Bonuri-consum_'+numar)
      //     blankWindow.close();
      //   },
      //   x: 15,
      //   y: 15,
      //   width: 180,
      //   windowWidth: 1200,
      // })
    },
    async refresh_info() {
      var response = await this.post("bonuri_consum/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("bonuri_consum");
    },
    async print(id) {

      let res = await this.post('bonuri_consum/print_bonuri_consum', {IdFactura: id});
      // console.log(res)
      // return

      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    },    
    reset() {
      this.Filters = {
        DataBonConsum: "",
        Numar: "",
      };
      this.refresh_info();
    },

    async delete_item(item) {
      this.$confirm(`Sunteti sigur ?`, "Warning", {
        type: "warning",
      })
        .then(async () => {
          await this.post("bonuri_consum/delete_item", { id: item.Id });
          this.refresh_info();
          this.$message({
            type: "success",
            message: "Stergere efectuata cu succes",
          });
        })
        .catch(() => {
          this.$message({ type: "info", message: "Stergere anulata" });
        });
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }
  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }
  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);
  .el-col {
    width: 50%;
  }

  .el-date-editor {
    width: 100%;
  }

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
